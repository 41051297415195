import { Component, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ListenerService } from 'src/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  checkForUpdateInterval: any;
  constructor(public updates: SwUpdate, private listenerService: ListenerService) {
    if (updates.isEnabled) {
      this.checkForUpdateInterval = setInterval(() => {
        console.log('checking for updates');
        this.checkForUpdates();
      }, 10000);
    } else {
      console.log('Update is not enabled.');
    }
  }

  checkForUpdates(): void {
    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe((res) => {
        if (res.type === 'VERSION_READY') {
          console.log('update available!');
          this.listenerService.applicationUpdateListener.next(null);
        }
      });
    } else {
      console.log('checkForUpdates: Update is not enabled.');
    }
  }

  ngOnDestroy(): void {
    if (this.checkForUpdateInterval) {
      this.checkForUpdateInterval.clearInterval();
    }
  }
}
